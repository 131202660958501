<template>
  <div class="app-container sys-city-page">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="城市名称" prop="cityName">
        <el-input
          v-model="queryParams.cityName"
          placeholder="请输入城市名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">

      </el-col>

    </el-row>

    <el-tree
      :props="{label:'name',children:'children'}"
      :data="cityList"
      node-key="id"
    >
      <div class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ data.name }}</span>
        <span class="custom-tree-action">
          <el-button type="text" size="mini" @click="() => appendNode(data)">新增</el-button>
          <el-button type="text" size="mini" @click="() => editNode(data)">编辑</el-button>
          <el-button type="text" size="mini" @click="() => handleDelete(data)">删除</el-button>
        </span>
      </div>
    </el-tree>


    <!-- 添加或修改城市对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="城市名称" prop="cityName">
          <el-input v-model="form.cityName" placeholder="请输入城市名称"/>
        </el-form-item>
        <el-form-item label="父节点id" prop="parentId">
          <el-cascader
            clearable
            :props="{label:'name',value:'id',expandTrigger:'hover',checkStrictly :true}"
            v-model="form.parentId"
            :options="cityList"
            placeholder="请输入父节点id"
          >
          </el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listCity, getCity, delCity, addCity, updateCity, exportCity
} from '@/api/system/city';
import _ from 'lodash';

export default {
  name: 'City',
  data() {
    return {
      cityMap: {},
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 城市表格数据
      cityList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        cityName: undefined,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        cityName: [
          {
            required: true,
            message: '城市名称不能为空',
            trigger: 'blur'
          }
        ],
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询城市列表 */
    getList() {
      this.loading = true;
      listCity(this.queryParams)
        .then((response) => {
          response = response.data;
          this.handleCityTreeData(response);
          this.cityList = this.handleCityTreeData(response);
          this.loading = false;
        });
    },
    handleCityTreeData(data) {
      const map = {};
      _.each(data, (x) => {
        _.set(x, 'children', []);
        map[x.id] = x;
      });
      _.each(data, (x) => {
        if (x.parentId) {
          map[x.parentId].children.push(x);
        }
      });
      const tree = _.filter(data, (x) => `${x.parentId}` === '0');
      this.removeLastChildren(tree);
      this.cityMap = map;
      return tree;
    },

    removeLastChildren(data) {
      _.each(data, (x) => {
        if (x.children.length) {
          this.removeLastChildren(x.children);
        } else {
          delete x.children;
        }
      });
    },

    appendNode(data) {
      this.title = '新增节点';
      this.reset();
      if (data) {
        const ids = this.findPath(data.id);
        this.$nextTick(() => {
          this.form.parentId = ids;
        });
      } else {
        this.$nextTick(() => {
          this.form.parentId = [0];
        });
      }
      this.open = true;
    },
    editNode(data) {
      this.title = '编辑';
      this.reset();
      let ids = this.findPath(data.id);
      if (ids.length === 1) {
        ids = [0];
      } else if (ids.length > 1) {
        ids.splice(ids.length - 1, 1);
      }
      this.open = true;
      this.$nextTick(() => {
        this.form.parentId = ids;
        this.form.cityName = data.name;
        this.form.id = data.id;
      });
    },

    findPath(id, parentIds = []) {
      parentIds.unshift(id);
      const pid = this.cityMap[id].parentId;
      if (pid) {
        this.findPath(pid, parentIds);
      }
      return parentIds;
    },

    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        cityName: undefined,
        parentId: undefined,
        parentIds: undefined,
        level: undefined
      };
      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '添加城市';
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids;
      getCity(id)
        .then((response) => {
          this.form = response.data;
          this.open = true;
          this.title = '修改城市';
        });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = { ...this.form, };

          params.parentId = params.parentId[params.parentId.length - 1];

          if (this.form.id != undefined) {
            updateCity(params)
              .then((response) => {
                this.msgSuccess('修改成功');
                this.open = false;
                this.getList();
              });
          } else {
            addCity(params)
              .then((response) => {
                this.msgSuccess('新增成功');
                this.open = false;
                this.getList();
              });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm(`是否确认删除城市为"${row.name}"的数据项?`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => delCity(ids))
        .then(() => {
          this.getList();
          this.msgSuccess('删除成功');
        })
        .catch(() => {
        });
    },
    /** 导出按钮操作 */
    handleExport() {
      const { queryParams } = this;
      this.$confirm('是否确认导出所有城市数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => exportCity(queryParams))
        .then((response) => {
          this.download(response.data);
        })
        .catch(() => {
        });
    }
  }
};
</script>


<style lang="less" scoped>
.sys-city-page {
  /deep/ .el-tree-node__content {
    width: 100%;
    height: 32px;
    line-height: 32px;

    .el-button.el-button--text.el-button--mini {
      //line-height: ;
    }
  }

  .custom-tree-node {
    position: relative;
    width: 100%;
  }

  .custom-tree-action {
    position: absolute;
    right: 0;
  }
}
</style>
