import request from '@/utils/request'

// /sys/city/delete
//   删除城市
//
// GET /sys/city/info/{id}
// 根据Id获得城市信息
//
// GET /sys/city/list
// 获得城市列表
//
// POST /sys/city/save
// 保存城市
//
// GET /sys/city/select
// 选择城市(添加、修改菜单)
//
// POST /sys/city/update
// 修改城市

// 查询城市列表
export function listCity(query) {
  return request({
    url: '/sys/city/list',
    method: 'get',
    params: query
  })
}

// 查询城市详细
export function getCity(id) {
  return request({
    url: `/sys/city/info/${id}`,
    method: 'get'
  })
}

// 新增城市
export function addCity(data) {
  return request({
    url: '/sys/city/save',
    method: 'post',
    data
  })
}

// 修改城市
export function updateCity(data) {
  return request({
    url: '/sys/city/update',
    method: 'post',
    data
  })
}

// 删除城市
export function delCity(id) {
  const data = [];
  data.push(id);
  return request({
    url: '/sys/city/delete',
    method: 'post',
    data
  })
}

// 导出城市
export function exportCity(query) {
  return request({
    url: '/city/export',
    method: 'get',
    params: query
  })
}
